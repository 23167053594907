import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { APP_ASSETS_PRODUCTS, APP_ASSETS_STORES } from "../../constants";
import ImageSelector from "../global/imageSelector/ImageSelector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { POST_CATALOG_PRODUCT_ADD_IMAGE } from "../../redux/actionTypes";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import SweetAlert from "sweetalert2";

const ProductImagesDialog = (props, ref) => {

 
  //SELECTORS
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  //VARIABLES
  const trans = settingsData.trans;

  const [images, setImages] = useState([]);

  // Step 1: Create a ref
  const imageSelectorRef = useRef();

  useImperativeHandle(ref, () => ({
    onImageAdded: () => {
        if (imageSelectorRef.current && imageSelectorRef.current.clearData) {
            imageSelectorRef.current.clearData();
          }
    },
  }));

  useEffect(() => {
    if (props.imagesData != null) {
      setImages(props.imagesData);
    }
  }, [props.imagesData]);

  const updateImageItem = (imageItem) => {
    let postData = {
      productId: props.itemId,
      img: {
        path: imageItem.img,
        width: imageItem.width,
        height: imageItem.height,
        aspect_ratio: imageItem.aspectRatio,
      },
      img_thumb: {
        path: imageItem.img_thumb,
        width: imageItem.thumbWidth,
        height: imageItem.thumbHeight,
        aspect_ratio: imageItem.thumbAspectRatio,
      },
    };
    props.onAddImage(postData);
  };

  // Handle reordering logic
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setImages(items);

    let orderedItems = [];
    orderedItems = items.map((item,index) => {
      return { id: item.id, order: index + 1 };
    });
    props.onItemsReorder(orderedItems, props.itemId);
  };

  const handleDelete = (item, index, event) => {
    event.stopPropagation(); // This stops the click from affecting the drag behavior

    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_image_title,
      text: trans.delete_image_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: settingsData.initData.app_color,
      confirmButtonText: trans.delete_image_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setImages(images.filter((_, i) => i !== index));
        props.deleteItemImage(item, props.itemId);
      }
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>
       {trans.manage_images_title}
        <IconButton
          onClick={props.onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ImageSelector
              ref={imageSelectorRef}
              title={trans.manage_images_add_image}
              desc={`${trans.graphics_best_fit} 1024 x 1024`}
              initialImageUrl={null}
              type="product"
              mainOnly="false"
              path={APP_ASSETS_PRODUCTS}
              onSave={(image) => updateImageItem(image)}
            />
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="imagesGrid" direction="horizontal">
                {(provided) => (
                  <Grid
                    container
                    spacing={2}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {images.map((image, index) => (
                      <Draggable
                        key={image.img_url}
                        draggableId={image.img_url}
                        index={index}
                      >
                        {(provided) => (
                          <Grid
                            item
                            xs={3}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              {...provided.dragHandleProps}
                              style={{
                                position: "relative", // This sets the positioning context for the IconButton
                                cursor: "grab",
                                width: "100%", // Ensure the div takes up the full width of the grid item
                                height: "100%", // Ensure the div takes up the full height as well
                              }}
                            >
                              <img
                                src={image.img_thumb_url}
                                alt={`Image ${index + 1}`}
                                style={{ width: "100%", display: "block" }}
                              />
                              <IconButton
                                onClick={(e) => handleDelete(image, index, e)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  margin: "8px", // Adjust as needed
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(ProductImagesDialog);

