import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

const DocumentationButton = () => {
  const [docUrl, setDocUrl] = useState('');
  
  const location = useLocation();
  const pathnameArray = location.pathname.split('/');
  const currentPath = pathnameArray[pathnameArray.length - 1];

  // SELECTORS
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;

  useEffect(() => { // ONLOAD
    if (settingsData != null && currentPath != null && currentPath !== "") {
      settingsData.adminMenusItems.forEach(element => {
        if (element.menu_type === currentPath) {
          setDocUrl(element.doc_url);
        }
      });
    }
  }, [currentPath, settingsData]);

  return (docUrl != "" ? <div>
    <span style={{marginLeft:'5px',marginRight:'5px'}}>•</span>
     <Button
      variant="outlined"
      color="primary"
      href={docUrl}
      target="_blank"
      rel="noopener noreferrer"
      disabled={!docUrl} // Disable the button if docUrl is not set
    >
      {trans.documentation_button}
    </Button>
  </div> : <div></div>
    
  );
};

export default DocumentationButton;
