import React from "react";
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { SortableCategory } from "./SortableCategory";

// Sortable components for the categories
export const SortableGroupedList = SortableContainer(({items, onCategoryEdit, onProductAddToCategory, trans, onSortEndProducts,onEditProduct}) => {
  return (
      <div>
        {items.map((group, index) => (
          <SortableCategory
            key={`group-${group.group_category.id}`}
            index={index}
            group={group}
            onCategoryEdit={onCategoryEdit}
            onProductAddToCategory={onProductAddToCategory}
            trans={trans}
            onSortEndProducts={(sortEvent) => onSortEndProducts(index, sortEvent)} // Adapting the callback to include groupIndex
            onEditProduct={onEditProduct}
          />
        ))}
      </div>
    );
});