import {
  CATALOG_IMPORT_DATA
  } from '../actionTypes';

  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import { DEBUG_MODE, MAIN_ID } from '../../constants';
  
 
  export const postCatalogImportData = (postData) => async dispatch => {
    //Get user locale of the machine he is on
    const userLocale = General.getLocale();
    postData.lancLoc = userLocale;

    const response = await axios.post(`scripts/import-catalog-data`,postData);
    if (DEBUG_MODE){
      console.log(response.data);
    }

    dispatch({type: CATALOG_IMPORT_DATA, payload: response.data});
  };
