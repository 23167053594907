import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { postStoresAll } from "../../redux/stores/action";
import { getUserStoreIds } from "../../redux/auth/action";

const StoresPicker = ({
  onStoreSelect,
  multiple = false,
  autoSelectFirst = false,
  preSelectedStoreIds = [],
  showOnlyUserStores = false,
}) => {
  const dispatch = useDispatch();
  const userInfoData = useSelector(({ auth }) => auth.userInfoData);
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const storesData = useSelector(({ stores }) => stores.storesData);

  const [storesList, setStoresList] = useState([]);
  const [selectedStores, setSelectedStores] = useState(multiple ? [] : null);

  useEffect(() => {
    const query = {};

    if (showOnlyUserStores) {
      query.storeId = getUserStoreIds(userInfoData.userInfo);
    }

    dispatch(postStoresAll(query));
  }, [dispatch, showOnlyUserStores, userInfoData]);

  useEffect(() => {
    if (storesData && storesData.stores) {
      setStoresList(storesData.stores);

      // Auto-select the first store if specified
      if (autoSelectFirst && !multiple && selectedStores === null) {
        const firstStore = storesData.stores[0];
        setSelectedStores(firstStore);
        onStoreSelect(firstStore.id);
      } else if (preSelectedStoreIds.length > 0) {
        const preSelectedStores = storesData.stores.filter((store) =>
          preSelectedStoreIds.includes(store.id)
        );

        setSelectedStores(multiple ? preSelectedStores : preSelectedStores[0]);
        onStoreSelect(multiple ? preSelectedStoreIds : preSelectedStoreIds[0]);
      }
    }
  }, [storesData]); // Correct dependency array to avoid infinite loops

  const handleSelectStore = (event, value) => {
    if (multiple) {
      const storeIds = value.map((store) => store.id);
      setSelectedStores(value);
      onStoreSelect(storeIds);
    } else {
      setSelectedStores(value);
      onStoreSelect(value.id);
    }
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple={multiple}
        id="stores-autocomplete"
        options={storesList}
        getOptionLabel={(option) => (option ? option.name : "")}
        value={selectedStores}
        onChange={handleSelectStore}
        renderInput={(params) => (
          <TextField
            {...params}
            label={settingsData.trans.store_picker_field}
            variant="outlined"
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Grid>
  );
};

export default StoresPicker;
