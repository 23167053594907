import React, { useState,useEffect } from 'react';
import './Login.css'; // Import the CSS for styling
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { USER_TYPES, WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { getUserStoreIds, postSignIn, postUserInfo } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { POST_SIGN_IN } from '../../redux/actionTypes';
import {appiko_logo_black} from '../../images';
import CopyRightWidget from '../global/copyrightwidget/CopyrightWidget';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { updateUserTopics } from '../../firebase/firebaseSet';

const SignIn = () => {

  const dispatch = useDispatch();

  //Selectors
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const signInData = useSelector(({auth}) => auth.signInData);
  const userInfoData = useSelector(({auth}) => auth.userInfoData);
 
  //States
  const [loading,setLoading] = useState(false);

  //Variables
  const trans = settingsData.trans;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
      if (signInData != null) {
          if (signInData.status) {
              getUserData()
          } else {
              setLoading(false);
              toast.dismiss();
              toast.error(signInData.message, {
                  position: toast.POSITION.TOP_RIGHT
                  });
          }
          dispatch({type: POST_SIGN_IN, payload: null});
      } 
  }, [signInData]);

  useEffect(() => {
      if (userInfoData != null) {
          if (userInfoData.id == ""){
              setLoading(false);
              return;
          }
          
          var preselectdstores = getUserStoreIds(userInfoData.userInfo);

          if (preselectdstores.length > 0) {
            updateUserTopics(true,preselectdstores);
          }
          

          window.location.reload();
      } 
  }, [userInfoData]);

  const getUserData = () => {
      if (signInData != null){
          let postData = {userId: signInData.userId};
          dispatch(postUserInfo(postData))
      }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
 
    let email = data.get('email');
    let password = data.get('password');

    setLoading(true);
    let postData = {email,password};
    dispatch(postSignIn(postData))

  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
           <img src={appiko_logo_black} alt="logo" className='_login_logo'/>
            
            <br/>
         
          <Typography component="h1" variant="h7" className='fontBold'>
            <span style={{textAlign:'center'}}>{trans.sign_in_title}</span>
          </Typography>

          <div style={{marginTop: "10px", marginBottom: "10px",textAlign:'center'}} className='fontBook'>
            {trans.sign_in_desc}
          </div>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={trans.email_field}
              name="email"
              autoComplete="email"
              autoFocus
            />
           <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={trans.pass_field}
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={trans.remember_me}
            /> */}
            <LoadingButton 
              className='fontMedium'
              loading={loading}    
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
                {trans.sign_in_btn}
            </LoadingButton>
           
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <br/>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <CopyRightWidget/>
        </Box>
       
      </Container>
  );
}

export default SignIn;