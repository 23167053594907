import React, { useEffect, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  Button,
  Autocomplete,
  InputAdornment,
  Paper,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  postAddEditProductExtra,
  postBulkEditProductExtra,
  postCatalogExtrasListAll,
} from "../../redux/catalog-extras/action";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import {
  POST_ADD_EDIT_PRODUCT_EXTRA,
  POST_BULK_EDIT_PRODUCT_EXTRA,
} from "../../redux/actionTypes";
import { postCatalogProductSingle } from "../../redux/catalog/action";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";

const ProductExtrasDialog = forwardRef((props, ref) => {
  //SELECTORS
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  //VARIABLES
  const trans = settingsData.trans;

  const catalogExtrasListData = useSelector(
    ({ catalogExtras }) => catalogExtras.catalogExtrasListData
  );
  const addEditProductExtraData = useSelector(
    ({ catalogExtras }) => catalogExtras.addEditProductExtraData
  );
  const singleCatalogProductData = useSelector(
    ({ catalog }) => catalog.singleCatalogProductData
  );

  const bulkEditProductExtraData = useSelector(
    ({ catalogExtras }) => catalogExtras.bulkEditProductExtraData
  );

  const [selectedExtra, setSelectedExtra] = useState(null); // Holds the entire selected extra object

  const [productItem, setProductItem] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.itemId != null && props.open) {
      reloadData();
    }
  }, [props.open, props.itemId]);

  useEffect(() => {
    if (addEditProductExtraData != null) {
      showResponseMessage(addEditProductExtraData);
      dispatch({ type: POST_ADD_EDIT_PRODUCT_EXTRA, payload: null });
      reloadData();
      props.onDataUpdated();
    }
  }, [addEditProductExtraData]);

  useEffect(() => {
    if (bulkEditProductExtraData != null) {
      showResponseMessage(bulkEditProductExtraData);
      dispatch({ type: POST_BULK_EDIT_PRODUCT_EXTRA, payload: null });
      reloadData();
      props.onDataUpdated();
    }
  }, [bulkEditProductExtraData]);

  useEffect(() => {
    if (singleCatalogProductData != null) {
      setProductItem(singleCatalogProductData);
    }
  }, [singleCatalogProductData]);

  const reloadData = () => {
    let postData = {};
    dispatch(postCatalogExtrasListAll(postData));

    getCatalogProduct(props.itemId);
  };

  const getCatalogProduct = (pId) => {
    let postData = {
      id: pId,
    };

    dispatch(postCatalogProductSingle(postData));
  };

  // Function to handle toggling the specific price status
  const toggleSpecificPriceStatus = (featureId, itemIndex, status) => {
    setProductItem((prev) => {
      // Deep copy to avoid direct mutation
      const newProductItem = JSON.parse(JSON.stringify(prev));
      const feature = newProductItem.extra_features.find(
        (f) => f._id === featureId
      );
      if (!feature) return prev; // Early return if feature not found

      const item = feature.specific_prices.find(
        (sp) =>
          sp.extra_item_id === feature.extra_item.extra_items[itemIndex]._id
      );
      if (item) {
        // If specific price entry exists, update its status
        item.status = status;
      } else {
        // Otherwise, create a new specific price entry
        feature.specific_prices.push({
          extra_item_id: feature.extra_item.extra_items[itemIndex]._id,
          price: feature.extra_item.extra_items[itemIndex].price, // Default to item's base price
          status: status,
        });
      }

      return newProductItem;
    });
  };

  const toggleHideExtra = (featureId, itemIndex) => {
    setProductItem((prev) => {
      const newItem = { ...prev };
      const feature = newItem.extra_features.find((f) => f._id === featureId);
      const itemId = feature.extra_item.extra_items[itemIndex]._id;

      // Toggle hide_extra based on current state
      if (feature.hide_extras.includes(itemId)) {
        feature.hide_extras = feature.hide_extras.filter((id) => id !== itemId);
      } else {
        feature.hide_extras.push(itemId);
      }

      return newItem;
    });
  };

  const handleSpecificPriceChange = (
    featureId,
    itemIndex,
    newPrice,
    newStatus
  ) => {
    setProductItem((prev) => {
      const newItem = { ...prev };
      const feature = newItem.extra_features.find((f) => f._id === featureId);
      const item = feature.extra_item.extra_items[itemIndex];
      const existingPrice = feature.specific_prices.find(
        (sp) => sp.extra_item_id === item._id
      );

      if (existingPrice) {
        existingPrice.price = parseFloat(newPrice);
        existingPrice.status = newStatus;
      } else {
        feature.specific_prices.push({
          extra_item_id: item._id,
          price: parseFloat(newPrice),
          status: newStatus,
        });
      }

      return newItem;
    });
  };

  const handleAddExtraSave = async (e) => {
    e.preventDefault();

    if (selectedExtra == null) {
      return;
    }

    // Assuming extraItem now needs the ID of the selected extra
    const extraFeature = {
      extra_item: selectedExtra ? selectedExtra.id : "",
      hide_extras: [],
      specific_prices: [],
      all_selection: selectedExtra.allitems,
    };

    const payload = { productId: props.itemId, extraFeature };

    dispatch(postAddEditProductExtra(payload));
  };

  const handleSaveExtraItemsChanges = (e) => {
    e.preventDefault();

    var extraFeatures = productItem.extra_features;

    const payload = { productId: props.itemId, extraFeatures };

    dispatch(postBulkEditProductExtra(payload));
  };

  const handleDeleteExtra = (e, featureIndex) => {
    e.preventDefault();
    
    setProductItem(currentProductItem => {
      // Create a new copy of the extra_features array to ensure immutability
      const updatedExtraFeatures = currentProductItem.extra_features.filter((_, index) => index !== featureIndex);
  
      // Return the updated product item with the updated extra_features array
      return { ...currentProductItem, extra_features: updatedExtraFeatures };
    });
  };
  

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {trans.manage_product_extras_title}
        <IconButton
          onClick={props.onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <br />
        {catalogExtrasListData != null ? (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems={"center"}
          >
            <Grid item xs={10}>
              <Autocomplete
                options={catalogExtrasListData.catalog_extras || []}
                getOptionLabel={(option) => option.name || ""}
                onChange={(event, newValue) => {
                  setSelectedExtra(newValue);
                  // Reset other fields if necessary
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={trans.product_extra_select_extra}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                onClick={(e) => {
                  handleAddExtraSave(e);
                }}
              >
                {trans.product_extra_add_extra}
              </Button>
            </Grid>

            <br />
            <br />

            <Grid item xs={12}>
              {productItem?.extra_features.map((feature, featureIndex) => (
                <Grid key={feature._id} item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Grid item xs={11}>
                      <div className="_flex_row">
                        <div className="fontBold">
                          {
                            feature.extra_item.names.find(
                              (name) => name.lang === "el"
                            )?.value
                          }
                        </div>
                        <div style={{ color: "red" }}>
                          {feature.extra_item.state !== "OPTIONAL" ? "*" : ""}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        style={{ color: "#f44336" }}
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => handleDeleteExtra(e,featureIndex)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    {feature.extra_item.extra_items.map((item, itemIndex) => {
                      const specificPrice = feature.specific_prices.find(
                        (sp) => sp.extra_item_id === item._id
                      ) || { price: item.price, status: false };

                      return (
                        <Grid key={item._id} item md={6}>
                          <Paper
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "16px",
                              margin: "8px",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <div>
                                  <div
                                    className="fontBold"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {
                                      item.names.find(
                                        (name) => name.lang === "el"
                                      )?.value
                                    }
                                  </div>
                                  <div
                                    className="fontBook"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {trans.catalog_extra_item_price}:{" "}
                                    {item.price}€
                                  </div>
                                  {item.fixed_price != null && item.fixed_price != 0 && item.fixed_price != 0.0 ?   <div
                                    className="fontBook"
                                    style={{ fontSize: "12px" ,color:"#EF5350" }}
                                  >
                                    {trans.catalog_extra_item_fixed_price}:{" "}
                                    {item.fixed_price}€
                                  </div> : null}
                                </div>
                              </Grid>

                              <Grid item xs={2}>
                                <Switch
                                  checked={
                                    !productItem.extra_features[
                                      featureIndex
                                    ].hide_extras.includes(item._id)
                                  }
                                  onChange={() =>
                                    toggleHideExtra(feature._id, itemIndex)
                                  }
                                  color="primary"
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <MyStatusPicker
                                  label={trans.product_extra_has_specific_price}
                                  status={specificPrice?.status || false}
                                  onChange={(newStatus) =>
                                    handleSpecificPriceChange(
                                      feature._id,
                                      itemIndex,
                                      specificPrice?.price || item.price,
                                      newStatus
                                    )
                                  }
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  label={trans.product_extra_add_sepicific_price_field}
                                  type="number"
                                  disabled={!specificPrice?.status}
                                  value={
                                    specificPrice?.price.toString() ||
                                    item.price.toString()
                                  }
                                  onChange={(e) =>
                                    handleSpecificPriceChange(
                                      feature._id,
                                      itemIndex,
                                      e.target.value,
                                      true
                                    )
                                  } // Assuming changing price automatically enables the specific price
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        €
                                      </InputAdornment>
                                    ),
                                  }}
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{trans.product_extra_add_cancel}</Button>
        <Button onClick={handleSaveExtraItemsChanges}>{trans.product_extra_add_save}</Button>
      </DialogActions>
    </Dialog>
  );
});

export default ProductExtrasDialog;
