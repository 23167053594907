import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { postCatalogProductList } from "../../redux/catalog/action";

const ProductsPicker = ({
  onProductSelect,
  multiple = false,
  autoSelectFirst = false,
  preSelectedProductIds = [],
  storeItems = []
}) => {
  const dispatch = useDispatch();

  // Redux state to get the product data
  const listCatalogProductData = useSelector(({ catalog }) => catalog.listCatalogProductData);

  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(multiple ? [] : null);
  const [loading, setLoading] = useState(false);

  // Fetch the product list when the component is mounted
  useEffect(() => {
    setLoading(true);
    dispatch(postCatalogProductList({queryStores:storeItems})); // Fetch the product list with any required parameters
  }, [dispatch]);

  useEffect(() => {
    if (listCatalogProductData) {
      setLoading(false);
      setProductsList(listCatalogProductData.products);

      if (preSelectedProductIds.length > 0) {
        const preSelectedProducts = listCatalogProductData.products.filter((product) =>
          preSelectedProductIds.includes(product.id)
        );

        setSelectedProducts(multiple ? preSelectedProducts : preSelectedProducts[0]);
        onProductSelect(
          multiple ? preSelectedProductIds : preSelectedProductIds[0]
        );
      } else if (autoSelectFirst && listCatalogProductData.products.length > 0) {
        const firstProduct = listCatalogProductData.products[0];
        setSelectedProducts(firstProduct);
        onProductSelect(firstProduct.id);
      }
    }
  }, [listCatalogProductData, preSelectedProductIds, multiple, autoSelectFirst, onProductSelect]);

  // Handle product selection in the Autocomplete component
  const handleSelectProduct = (event, value) => {
    if (multiple) {
      const productIds = value.map((product) => product.id);
      setSelectedProducts(value);
      onProductSelect(productIds);
    } else {
      setSelectedProducts(value);
      onProductSelect(value ? value.id : null);
    }
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple={multiple}
        id="products-autocomplete"
        options={productsList}
        getOptionLabel={(option) => (option ? option.name : "")}
        value={selectedProducts}
        onChange={handleSelectProduct}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Products"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Grid>
  );
};

export default ProductsPicker;
