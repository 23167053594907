import React from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { SortableProductList } from "./SortableProductList";
import { Button, Divider, Paper } from "@mui/material";

// Sortable component for each category
export const SortableCategory = SortableElement(
  ({
    group,
    onCategoryEdit,
    onProductAddToCategory,
    trans,
    onSortEndProducts,
    onEditProduct
  }) => {
    return (
      <Paper elevation={2} style={{ marginTop: "10px", padding: "10px" }}>
        <div className="_flex_row_space">
          <h5>{group.group_category.name}</h5>
          <Button
            onClick={(e) => onCategoryEdit(e, group)}
            color="primary"
            variant="contained"
          >
            {trans.catalog_menu_category_edit}
          </Button>
        </div>
        <Divider />
        <SortableProductList
          items={group.group_products}
          trans={trans}
          useDragHandle={true}
          onSortEnd={onSortEndProducts}
          onEditProduct={onEditProduct}
        />
        <Divider />
        <div className="_flex_column">
          <Button
            onClick={(e) => onProductAddToCategory(e, group)}
            color="primary"
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            {trans.menu_list_category_add_product}
          </Button>
        </div>
      </Paper>
    );
  }
);
