import {
  CATALOG_IMPORT_DATA
} from '../actionTypes';
  
const INIT_STATE = {
  catalogImportData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case CATALOG_IMPORT_DATA:
               return {
                 ...state,
                 catalogImportData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;