import './InitGraphics.css';
import React, { useState,useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import PageTitleDesc from '../global/pageTitleDesc/PageTitleDesc';
import ImageSelector from '../global/imageSelector/ImageSelector';
import { postUploadImage } from '../../redux/upload/action';
import { APP_ASSETS } from '../../constants';
import { toast } from 'react-toastify';
import { ADD_EDIT_INIT_DATA, BULK_EDIT_INIT_DATA, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import { addEditInitData, postBulkEditData } from '../../redux/settings/action';
import { Button, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import MyColorPicker from '../global/colorPicker/MyColorPicker';
import MyStatusPicker from '../global/statusPicker/MyStatusPicker';


const InitGraphics = (props) => {

  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const addEditInitDataResponse = useSelector(({settings}) => settings.addEditInitDataResponse);
  const bulkEditInitDataResponse = useSelector(({settings}) => settings.bulkEditInitDataResponse);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS
  const [powered_by_text,setPoweredByText] = useState("");
  const [poweredByBg, setPoweredByBg] = useState("");
  const [poweredByTextColor, setPoweredByTextColor] = useState("");
  const [powerByHasBg, setPowerByHasBg] = useState(false);
  const [powerByIconStatus, setPowerByIconStatus] = useState(false); 
  const [app_color, setAppColor] = useState("");
  const [app_text_color, setAppTextColor] = useState("");
  const [app_bar_bg_color, setAppBarBgColor] = useState("");
  const [app_bar_text_color, setAppBarTextColor] = useState("");
  const [menu_bg_color, setMenuBgColor] = useState("");
  const [header_bg_color, setHeaderBgColor] = useState("");
  const [header_text_color, setHeaderTextColor] = useState("");
  const [alert_color_bg, setAlertColorBg] = useState("");
  const [alert_color_text, setAlertColorText] = useState("");
  const [progress_color, setProgressColor] = useState("");
  const [app_text_color_dark_mode, setAppTextColorDarkMode] = useState("");
  const [app_bar_bg_color_dark_mode, setAppBarBgColorDarkMode] = useState("");
  const [app_background_color, setAppBackgroundColor] = useState("");
  const [app_background_color_dark_mode, setAppBackgroundColorDarkMode] = useState("");
  const [app_second_background_color_dark_mode, setAppSecondBackgroundColorDarkMode] = useState("");
  const [app_second_background_color, setAppSecondBackgroundColor] = useState("");
  const [app_bar_text_color_dark_mode, setAppBarTextColorDarkMode] = useState("");
  const [button_background_color, setButtonBackgroundColor] = useState("");
  const [button_text_color, setButtonTextColor] = useState("");
  const [app_second_text_color, setAppSecondTextColor] = useState("");
  const [app_second_text_dark_mode_color, setAppSecondTextDarkModeColor] = useState("");
  const [button_radius,setButtonRadius] = useState(12.5);
  const [logo_circle,setLogoCircle] = useState(true);
  
  
  useEffect(() => { 
    if (settingsData != null){
      setPoweredByText(settingsData.initData.powered_by_text);
      setPoweredByBg(settingsData.initData.powered_by_bg);
      setPoweredByTextColor(settingsData.initData.powered_by_text_color);
      setPowerByHasBg(settingsData.initData.power_by_has_bg);
      setPowerByIconStatus(settingsData.initData.power_by_icon_status);
      setAppColor(settingsData.initData.app_color || "");
      setAppTextColor(settingsData.initData.app_text_color || "");
      setAppBarBgColor(settingsData.initData.app_bar_bg_color || "");
      setAppBarTextColor(settingsData.initData.app_bar_text_color || "");
      setMenuBgColor(settingsData.initData.menu_bg_color || "");
      setHeaderBgColor(settingsData.initData.header_bg_color || "");
      setHeaderTextColor(settingsData.initData.header_text_color || "");
      setAlertColorBg(settingsData.initData.alert_color_bg || "");
      setAlertColorText(settingsData.initData.alert_color_text || "");
      setProgressColor(settingsData.initData.progress_color || "");
      setAppTextColorDarkMode(settingsData.initData.app_text_color_dark_mode || "");
      setAppBarBgColorDarkMode(settingsData.initData.app_bar_bg_color_dark_mode || "");
      setAppBackgroundColor(settingsData.initData.app_background_color || "");
      setAppBackgroundColorDarkMode(settingsData.initData.app_background_color_dark_mode || "");
      setAppSecondBackgroundColor(settingsData.initData.app_second_background_color || "");
      setAppSecondBackgroundColorDarkMode(settingsData.initData.app_second_background_color_dark_mode || "");
      setAppBarTextColorDarkMode(settingsData.initData.app_bar_text_color_dark_mode || "");
      setButtonBackgroundColor(settingsData.initData.button_background_color || "");
      setButtonTextColor(settingsData.initData.button_text_color || "");
      setAppSecondTextColor(settingsData.initData.app_second_text_color || "");
      setAppTextColorDarkMode(settingsData.initData.app_second_text_dark_mode_color || "");
      setButtonRadius(settingsData.initData.button_radius || 12.5);
      setLogoCircle(settingsData.initData.logo_circle);
    }
  }, [settingsData]);

  
   useEffect(() => { 
    if (addEditInitDataResponse != null){
      if (addEditInitDataResponse.status) {
        toast.dismiss();
        toast.success(addEditInitDataResponse.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        
      } else {
        toast.dismiss();
        toast.error(addEditInitDataResponse.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: ADD_EDIT_INIT_DATA, payload: null});
    }
  }, [addEditInitDataResponse]);

  useEffect(() => { 
    if (bulkEditInitDataResponse != null){
      if (bulkEditInitDataResponse.status) {
        toast.dismiss();
        toast.success(bulkEditInitDataResponse.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        
      } else {
        toast.dismiss();
        toast.error(bulkEditInitDataResponse.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: BULK_EDIT_INIT_DATA, payload: null});
    }
  }, [bulkEditInitDataResponse]);
  
  const updateItem = (value,key,type) => {
    var postData = {
      "key": key,
      "data_type": type,
      "value": value
    };
    dispatch(addEditInitData(postData));
  }

  const updateImageItem = (uploadImageData) => {
    if (uploadImageData.img != null){
     updateItem(uploadImageData.img,uploadImageData.assettype,"IMAGE");
    }
  }

  const handleSettingsSave = () => {
    var postData = {};
    
    var items = [];

    items.push({"key": "powered_by_text", "value": powered_by_text});
    items.push({"key": "power_by_has_bg", "value": powerByHasBg});
    items.push({"key": "power_by_icon_status", "value": powerByIconStatus});
    items.push({"key": "powered_by_bg", "value": poweredByBg});
    items.push({"key": "powered_by_text_color", "value": poweredByTextColor});
    
    items.push({ "key": "app_color", "value": app_color || "" });
    items.push({ "key": "app_text_color", "value": app_text_color || "" });
    items.push({ "key": "app_bar_bg_color", "value": app_bar_bg_color || "" });
    items.push({ "key": "app_bar_text_color", "value": app_bar_text_color || "" });
    items.push({ "key": "menu_bg_color", "value": menu_bg_color || "" });
    items.push({ "key": "header_bg_color", "value": header_bg_color || "" });
    items.push({ "key": "header_text_color", "value": header_text_color || "" });
   
    items.push({ "key": "alert_color_bg", "value": alert_color_bg || "" });
    items.push({ "key": "alert_color_text", "value": alert_color_text || "" });
    items.push({ "key": "progress_color", "value": progress_color || "" });
   
    items.push({ "key": "app_text_color_dark_mode", "value": app_text_color_dark_mode || "" });
    items.push({ "key": "app_bar_bg_color_dark_mode", "value": app_bar_bg_color_dark_mode || "" });
    items.push({ "key": "app_background_color", "value": app_background_color || "" });
    items.push({ "key": "app_background_color_dark_mode", "value": app_background_color_dark_mode || "" });
    items.push({ "key": "app_second_background_color", "value": app_second_background_color || "" });
    items.push({ "key": "app_second_background_color_dark_mode", "value": app_second_background_color_dark_mode || "" });
    items.push({ "key": "app_bar_text_color_dark_mode", "value": app_bar_text_color_dark_mode || "" });
    items.push({ "key": "button_background_color", "value": button_background_color || "" });
    items.push({ "key": "button_text_color", "value": button_text_color || "" });
    items.push({ "key": "app_second_text_color", "value": app_second_text_color || "" });
    items.push({ "key": "app_second_text_dark_mode_color", "value": app_second_text_dark_mode_color || "" });
    items.push({ "key": "button_radius", "value": button_radius || 12.5 });
    items.push({ "key": "logo_circle", "value": logo_circle });
    


    
    postData.items = items;

    dispatch(postBulkEditData(postData));
  }
  
  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <PageTitleDesc title={trans.menu_graphics} desc={trans.menu_graphics_desc}/>
        <br/>
        <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={3}>
            <ImageSelector
              title={trans.graphics_logo}
              desc={`${trans.graphics_best_fit} 1024 x 1024`}
              initialImageUrl={settingsData.initData.app_logo}
              type="app_logo"
              onSave={(image) => updateImageItem(image)}/>
          </Grid>
          <Grid item  s={12} xs={12}  md={3}>
            <ImageSelector
              title={trans.graphics_logo_dark}
              desc={`${trans.graphics_best_fit} 1024 x 1024`}
              initialImageUrl={settingsData.initData.app_logo_dark_mode}
              type="app_logo_dark_mode"
              onSave={(image) => updateImageItem(image)}/>
          </Grid>
          <Grid item  s={12} xs={12}  md={3}>
            <ImageSelector
              title={trans.graphics_splash}
              desc={`${trans.graphics_best_fit} 1080 x 1920`}
              initialImageUrl={settingsData.initData.splash_image}
              type="splash_image"
              onSave={(image) => updateImageItem(image)}/>
          </Grid>
          <Grid item  s={12} xs={12}  md={3}>
            <ImageSelector
              title={trans.graphics_power_by_icon_url}
              desc={`${trans.graphics_best_fit} 400 x 400`}
              initialImageUrl={settingsData.initData.power_by_icon_url}
              type="power_by_icon_url"
              onSave={(image) => updateImageItem(image)}/>
          </Grid>
          <Grid item  s={12} xs={12}  md={3}>
            <ImageSelector
              title={trans.graphics_welcome_bg_image}
              desc={`${trans.graphics_best_fit} 400 x 400`}
              initialImageUrl={settingsData.initData.welcome_bg_image}
              type="welcome_bg_image"
              onSave={(image) => updateImageItem(image)}/>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item md={10}>
            <PageTitleDesc title={trans.graphics_settings_title} desc={trans.graphics_settings_desc}/>
          </Grid>
          <Grid item md={2}>
            <Button style={{marginTop:'10px'}} startIcon={<SaveIcon />} size="small" color="primary" onClick={(e) => handleSettingsSave(e)}  variant="contained">{trans.save_settings_button}</Button>
          </Grid>
        </Grid>
       
        <br/>
        <h5>Powered By</h5>
        <Grid
            spacing={2}
            container
            direction="row"
            alignItems="center">
              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="powered_by_text"
                  label={trans.powered_by_text_field}
                  value={powered_by_text}
                  type="text"
                  onChange={(e)=> {setPoweredByText(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>
              <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.powered_by_text_color_field}
                  color={poweredByTextColor}
                  onChangeColor={(color) => {
                    setPoweredByTextColor(color)
                  }}
                />
              </Grid>
              <Grid item s={12} xs={12} md={4}>
                <MyColorPicker
                  labelText={trans.powered_by_bg_field}
                  color={poweredByBg}
                  onChangeColor={(color) => {
                    setPoweredByBg(color)
                  }}
                />
              </Grid>
              <Grid item s={12} xs={12} md={4}>
                <MyStatusPicker
                  label={trans.power_by_has_bg_field}
                  status={powerByHasBg}
                  onChange={(value) => setPowerByHasBg(value)}
                />
              </Grid>
              <Grid item s={12} xs={12} md={4}>
                <MyStatusPicker
                    label={trans.power_by_icon_status_field}
                    status={powerByIconStatus}
                    onChange={(value) => setPowerByIconStatus(value)}
                  />
              </Grid>
        </Grid>
        <br/>
        <h5>App</h5>
        <Grid
            spacing={2}
            container
            direction="row"
            alignItems="center">
          
            <Grid item s={12} xs={12} md={4}>
                <MyColorPicker
                  labelText={trans.app_color_field}
                  color={app_color}
                  onChangeColor={(color) => {
                    setAppColor(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={4}>
                <MyColorPicker
                  labelText={trans.app_text_color_field}
                  color={app_text_color}
                  onChangeColor={(color) => {
                    setAppTextColor(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={4}>
                <MyColorPicker
                  labelText={trans.app_text_color_dark_mode_field}
                  color={app_text_color_dark_mode}
                  onChangeColor={(color) => {
                    setAppTextColorDarkMode(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_background_color_field}
                  color={app_background_color}
                  onChangeColor={(color) => {
                    setAppBackgroundColor(color)
                  }}
                />
            </Grid>
            
            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_background_color_dark_mode_field}
                  color={app_background_color_dark_mode}
                  onChangeColor={(color) => {
                    setAppBackgroundColorDarkMode(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_second_background_color_field}
                  color={app_second_background_color}
                  onChangeColor={(color) => {
                    setAppSecondBackgroundColor(color)
                  }}
                />
            </Grid>
            
            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_second_background_color_dark_mode_field}
                  color={app_second_background_color_dark_mode}
                  onChangeColor={(color) => {
                    setAppSecondBackgroundColorDarkMode(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_second_text_color_field}
                  color={app_second_text_color}
                  onChangeColor={(color) => {
                    setAppSecondTextColor(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_second_text_dark_mode_color_field}
                  color={app_second_text_dark_mode_color}
                  onChangeColor={(color) => {
                    setAppSecondTextDarkModeColor(color)
                  }}
                />
            </Grid>

          </Grid>  

          <br/>
          <h5>Bar</h5>
          <Grid
              spacing={2}
              container
              direction="row"
              alignItems="center">

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_bar_bg_color_field}
                  color={app_bar_bg_color}
                  onChangeColor={(color) => {
                    setAppBarBgColor(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_bar_bg_color_dark_mode_field}
                  color={app_bar_bg_color_dark_mode}
                  onChangeColor={(color) => {
                    setAppBarBgColorDarkMode(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_bar_text_color_field}
                  color={app_bar_text_color}
                  onChangeColor={(color) => {
                    setAppBarTextColor(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.app_bar_text_color_dark_mode_field}
                  color={app_bar_text_color_dark_mode}
                  onChangeColor={(color) => {
                    setAppBarTextColorDarkMode(color)
                  }}
                />
            </Grid>
          </Grid>
       
            <br/>
          <h5>Header</h5>
          <Grid
              spacing={2}
              container
              direction="row"
              alignItems="center">
            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.header_bg_color_field}
                  color={header_bg_color}
                  onChangeColor={(color) => {
                    setHeaderBgColor(color)
                  }}
                />
            </Grid>
            <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.header_text_color_field}
                  color={header_text_color}
                  onChangeColor={(color) => {
                    setHeaderTextColor(color)
                  }}
                />
            </Grid>

            </Grid>
            
            <br/>
            <h5>Buttons</h5>
            <Grid
                spacing={2}
                container
                direction="row"
                alignItems="center">
              <Grid item s={12} xs={12} md={4}>
                  <MyColorPicker
                    labelText={trans.button_background_color_field}
                    color={button_background_color}
                    onChangeColor={(color) => {
                      setButtonBackgroundColor(color)
                    }}
                  />
              </Grid>
              <Grid item s={12} xs={12} md={4}>
                  <MyColorPicker
                    labelText={trans.button_text_color_field}
                    color={button_text_color}
                    onChangeColor={(color) => {
                      setButtonTextColor(color)
                    }}
                  />
              </Grid>
              <Grid item s={12} xs={12} md={4}>
                <TextField
                  margin="dense"
                  id="button_radius"
                  label={trans.button_radius_field}
                  value={button_radius}
                  type="number"
                  onChange={(e)=> {setButtonRadius(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>
              

          </Grid>

          <br/>
            <h5>Alert</h5>
            <Grid
                spacing={2}
                container
                direction="row"
                alignItems="center">
              <Grid item s={12} xs={12} md={6}>
                  <MyColorPicker
                    labelText={trans.alert_color_bg_field}
                    color={alert_color_bg}
                    onChangeColor={(color) => {
                      setAlertColorBg(color)
                    }}
                  />
              </Grid>
              <Grid item s={12} xs={12} md={6}>
                  <MyColorPicker
                    labelText={trans.alert_color_text_field}
                    color={alert_color_text}
                    onChangeColor={(color) => {
                      setAlertColorText(color)
                    }}
                  />
              </Grid>
          </Grid>

            <br/>
          <h5>General</h5>
          <Grid
              spacing={2}
              container
              direction="row"
              alignItems="center">

              <Grid item s={12} xs={12} md={6}>
                  <MyColorPicker
                    labelText={trans.menu_bg_color_field}
                    color={menu_bg_color}
                    onChangeColor={(color) => {
                      setMenuBgColor(color)
                    }}
                  />
              </Grid>

              <Grid item s={12} xs={12} md={6}>
                <MyColorPicker
                  labelText={trans.progress_color_field}
                  color={progress_color}
                  onChangeColor={(color) => {
                    setProgressColor(color)
                  }}
                />
            </Grid>

            <Grid item s={12} xs={12} md={4}>
                <MyStatusPicker
                  label={trans.has_logo_circle}
                  status={logo_circle}
                  onChange={(value) => setLogoCircle(value)}
                />
              </Grid>

            </Grid>
      
      </Paper>
    </Grid>
  </Grid>


</Container>);
}

export default InitGraphics;