import "./AddressAutocomplete.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { usePlacesWidget } from "react-google-autocomplete";

const AddressAutocomplete = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
    //VARIABLES
    const trans = settingsData.trans;


  const [address, setAddress] = useState("");

  useEffect(() => {
    // Set the address state to the initial address if it exists
    if (props.initialAddressItem && props.initialAddressItem.address != null) {
      setAddress(`${props.initialAddressItem.address} ${props.initialAddressItem.street_number || ''} ${props.initialAddressItem.area || ''} ${props.initialAddressItem.postalCode || ''}`);
    }
  }, [props.initialAddressItem]);

  const handleChange = (place) => {
    const getAddressComponent = (type) => {
      return (
        place.address_components.find(
          (component) => component.types.indexOf(type) >= 0
        )?.long_name || ""
      );
    };

    const addressObject = {
      address: getAddressComponent("route"),
      street_number: getAddressComponent("street_number"),
      area: getAddressComponent("locality"),
      postalCode: getAddressComponent("postal_code"),
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    props.onAddressSelect(addressObject); // Call the callback function with the new address object
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: settingsData.initData.maps_key,
    onPlaceSelected: (place) => handleChange(place),
    options: {
      types: ["address"], // This restricts search to only addresses
    },
  });

  return (
    <div className="my-autocomplete-container">
      <TextField
      label={trans.enter_location_field}
      placeholder={trans.enter_location_field}
        fullWidth
        color="secondary"
        variant="standard"
        value={address}
        onChange={(e) => setAddress(e.target.value)} // Update local state with user input
        inputRef={materialRef}
      />
    </div>
  );
};

export default AddressAutocomplete;
