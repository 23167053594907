import './InitSettings.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import PageTitleDesc from '../global/pageTitleDesc/PageTitleDesc';
import SaveIcon from '@mui/icons-material/Save';
import { TextField } from '@mui/material';
import { postBulkEditData } from '../../redux/settings/action';
import { toast } from 'react-toastify';
import { BULK_EDIT_INIT_DATA } from '../../redux/actionTypes';
import MyListPicker from '../global/listPicker/MyListPicker';
import MyStatusPicker from '../global/statusPicker/MyStatusPicker';
import SweetAlert from 'sweetalert2';
import { PRIMARY_COLOR } from '../../constants';

const InitSettings = () => {

  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const bulkEditInitDataResponse = useSelector(({settings}) => settings.bulkEditInitDataResponse);
  const userTypeAdmin = useSelector(({auth}) => auth.userTypeAdmin);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;


  //App Settings
  const [app_name,setAppName] = useState("");
  const [splash_wait_time, setSplashWaitTime] = useState(3);
  const [ios_app_url, setIosAppUrl] = useState("");
  const [android_app_url, setAndroidAppUrl] = useState("");
  const [app_type, setAppType] = useState("FOOD");
  const [app_shop_type, setAppShopType] = useState("");
  const [has_mobile_verify, setHasMobileVerify] = useState(false);
  const [search_address_local, setSearchAddressLocal] = useState(false);
  const [geocode_local, setGeocodeLocal] = useState(false);

  //App Keys
  const [message_bird_key, setMessageBirdKey] = useState("");
  const [android_maps_key, setAndroidMapsKey] = useState("");
  const [ios_maps_key, setIosMapsKey] = useState("");
  const [firebase_key, setFirebaseKey] = useState("");
  
  const [app_languages, setAppLanguages] = useState([]);
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [shopTypes, setShopTypes] = useState([]);
   
  useEffect(() => { 
    if (settingsData != null){
      setAppName(settingsData.initData.app_name);
      setSplashWaitTime(settingsData.initData.splash_wait_time);
      setIosAppUrl(settingsData.initData.ios_app_url);
      setAndroidAppUrl(settingsData.initData.android_app_url);
      setAppType(settingsData.initData.app_type);
      setAppShopType(settingsData.initData.app_shop_type);
      setHasMobileVerify(settingsData.initData.has_mobile_verify);
      setSearchAddressLocal(settingsData.initData.search_address_local);
      setGeocodeLocal(settingsData.initData.geocode_local);

      setMessageBirdKey(settingsData.initData.message_bird_key);
      setAndroidMapsKey(settingsData.initData.android_maps_key);
      setIosMapsKey(settingsData.initData.ios_maps_key);
      setFirebaseKey(settingsData.initData.firebase_key);
      setAppLanguages(settingsData.app_languages);

      var applicationTypesOptions = [];
      settingsData.app_types_list.forEach((element) => {
        applicationTypesOptions.push({
          value: element.app_type_value,
          text: element.app_type_text,
        });
      });
      setApplicationTypes(applicationTypesOptions);

      var shopTypesOptions = [];
      settingsData.shopTypesItems.forEach(element => {
        shopTypesOptions.push({
          value: element.shop_type_id,
          text: element.shop_name,
        })
      });
      setShopTypes(shopTypesOptions);

    }
  }, [settingsData]);
  

  useEffect(() => { 
    if (bulkEditInitDataResponse != null){
      if (bulkEditInitDataResponse.status) {
        toast.dismiss();
        toast.success(bulkEditInitDataResponse.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        
      } else {
        toast.dismiss();
        toast.error(bulkEditInitDataResponse.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: BULK_EDIT_INIT_DATA, payload: null});
    }
  }, [bulkEditInitDataResponse]);

  const handleSettingsSave = (e) => {
    e.preventDefault();

    var postData = {};
    
    var items = [];

    items.push({"key": "app_name", "value": app_name});
    items.push({"key": "splash_wait_time", "value": splash_wait_time});
    items.push({"key": "ios_app_url", "value": ios_app_url});
    items.push({"key": "android_app_url", "value": android_app_url});


    items.push({"key": "app_type", "value": app_type});
    items.push({"key": "app_shop_type", "value": app_shop_type});
    
    items.push({"key": "has_mobile_verify", "value": has_mobile_verify});
    items.push({"key": "search_address_local", "value": search_address_local});
    items.push({"key": "geocode_local", "value": geocode_local});

    items.push({"key": "message_bird_key", "value": message_bird_key});
    items.push({"key": "android_maps_key", "value": android_maps_key});
    items.push({"key": "ios_maps_key", "value": ios_maps_key});
    items.push({"key": "firebase_key", "value": firebase_key});


    postData.items = items;

    dispatch(postBulkEditData(postData));
  }

  const askToActivateLanguage = (e,langItem) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title:trans.app_language_status_ask_title,
      text:trans.app_language_status_ask_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText:trans.app_language_status_ask_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        handleLanguageEdit(langItem);
      }
    })
  }

  const handleLanguageEdit = (langItem) => {
  
    var fixedAppLanguages = app_languages.map(appLanguage => {
      // We'll directly return a new object for each language.
      if (appLanguage.id === langItem.id) {
        // Toggle the 'selected' status only if the current language matches the one being edited.
        return { ...appLanguage, selected: !appLanguage.selected };
      } else {
        // For all other languages, return them as they are.
        return appLanguage;
      }
    });


   // Count how many languages are currently selected
  const selectedCount = fixedAppLanguages.filter(lang => lang.selected).length;

  // If there's exactly one selected language, adjust the is_default flags
  if (selectedCount === 1) {
    fixedAppLanguages = fixedAppLanguages.map(lang => ({
      ...lang,
      is_default: lang.selected // Set is_default true only for the selected language
    }));
  } else {
    // If more than one or none are selected, ensure all is_default flags are false
    fixedAppLanguages = fixedAppLanguages.map(lang => ({
      ...lang,
      is_default: lang.is_default == true ? true : false
    }));
  }
    
    setAppLanguages(fixedAppLanguages);
    
    //Update server data
    var selectedLanguagesData = [];
    fixedAppLanguages.forEach(element => {
      if (element.selected == true){
        selectedLanguagesData.push({
          "id": element.id,
          "is_default": element.is_default,
          "active": true
        });
      }
    });

  
    var postData = {};
    
    var items = [];

    items.push({"key": "selected_langs", "value": selectedLanguagesData});

    postData.items = items;

    console.log(postData);

    dispatch(postBulkEditData(postData));
  }

  const setDefaultLanguage = (selectedId) => {
    const updatedLanguages = app_languages.map(lang => {
      return {
        ...lang,
        is_default: lang.id === selectedId
      };
    });
  
    // Assuming you have a method to update the state of your languages
    setAppLanguages(updatedLanguages);

      //Update server data
      var selectedLanguagesData = [];
      updatedLanguages.forEach(element => {
          selectedLanguagesData.push({
            "id": element.id,
            "is_default": element.is_default,
            "active": true
          });
      });
    
      var postData = {};
      
      var items = [];
  
      items.push({"key": "selected_langs", "value": selectedLanguagesData});
  
      postData.items = items;
  
      console.log(postData);
  
      dispatch(postBulkEditData(postData));
  };

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <PageTitleDesc title={trans.menu_settings} desc={trans.menu_settings_desc}/>       
      </Paper>
    </Grid>


    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid
            spacing={2}
            container
            direction="row"
            alignItems="center">
          <Grid item md={10}>
            <PageTitleDesc title={trans.app_settings_title} desc={trans.app_settings_desc}/>
          </Grid>
          <Grid item md={2}>
            <Button style={{marginTop:'10px'}} startIcon={<SaveIcon />} size="small" color="primary" onClick={(e) => handleSettingsSave(e)}  variant="contained">{trans.save_settings_button}</Button>
          </Grid>
        </Grid>
       
        <br/>
        <Grid
            spacing={4}
            container
            direction="row"
            alignItems="center">

          {userTypeAdmin == true ? 
            <Grid item s={12} xs={12}  md={6}>
                <MyListPicker
                    labelId={"app_type"}
                    label={trans.app_type_field}
                    selected={app_type}
                    onChange={(value) => setAppType(value)}
                    list={applicationTypes}
                />
             </Grid> : null }

             {userTypeAdmin == true ? 
            <Grid item s={12} xs={12}  md={6}>
                <MyListPicker
                    labelId={"app_shop_type"}
                    label={trans.app_shop_type_field}
                    selected={app_shop_type}
                    onChange={(value) => setAppShopType(value)}
                    list={shopTypes}
                />
             </Grid> : null }
              
             
              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="app_name"
                  label={trans.app_name_field}
                  value={app_name}
                  type="text"
                  onChange={(e)=> {setAppName(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>
              
              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="splash_wait_time"
                  label={trans.splash_wait_time_field}
                  value={splash_wait_time}
                  type="number"
                  onChange={(e)=> {setSplashWaitTime(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>

              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="ios_app_url"
                  label={trans.ios_app_url_field}
                  value={ios_app_url}
                  type="text"
                  onChange={(e)=> {setIosAppUrl(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>


              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="android_app_url"
                  label={trans.android_app_url_field}
                  value={android_app_url}
                  type="text"
                  onChange={(e)=> {setAndroidAppUrl(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>


              <Grid item s={12} xs={12}  md={4}>
                <MyStatusPicker
                    label={trans.has_mobile_verify_field}
                    status={has_mobile_verify}
                    onChange={(value) => setHasMobileVerify(value)}
                  />
              </Grid>

              <Grid item s={12} xs={12}  md={4}>
                <MyStatusPicker
                    label={trans.search_address_local_field}
                    status={search_address_local}
                    onChange={(value) => setSearchAddressLocal(value)}
                  />
              </Grid>

              <Grid item s={12} xs={12}  md={4}>
                <MyStatusPicker
                    label={trans.geocode_local_field}
                    status={geocode_local}
                    onChange={(value) => setGeocodeLocal(value)}
                  />
              </Grid>

        </Grid>
       
       <br/>
       <h4>{trans.app_settings_keys_title}</h4>
       <Grid
            spacing={4}
            container
            direction="row"
            alignItems="center">
            
             
              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="message_bird_key"
                  label={trans.message_bird_key_field}
                  value={message_bird_key}
                  type="text"
                  onChange={(e)=> {setMessageBirdKey(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>

              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="android_maps_key"
                  label={trans.android_maps_key_field}
                  value={android_maps_key}
                  type="text"
                  onChange={(e)=> {setAndroidMapsKey(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>

              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="ios_maps_key"
                  label={trans.ios_maps_key_field}
                  value={ios_maps_key}
                  type="text"
                  onChange={(e)=> {setIosMapsKey(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>
              
              <Grid item s={12} xs={12}  md={6}>
                <TextField
                  margin="dense"
                  id="firebase_key"
                  label={"Firebase Key"}
                  value={firebase_key}
                  type="text"
                  onChange={(e)=> {setFirebaseKey(e.target.value)}}
                  fullWidth
                  variant="standard" />
              </Grid>
              
              

        </Grid>
      
      </Paper>
    </Grid>

    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Grid
              spacing={2}
              container
              direction="row"
              alignItems="center">
            <Grid item md={12}>
              <PageTitleDesc title={trans.app_languages_title} desc={trans.app_languages_desc}/>
            </Grid>
          </Grid>
          <br/>
          <Grid>
          {app_languages.map((langItem, langItemIndex) => {
            return (
              <div key={`app_lang_${langItem.id}`} className='_flex_row' style={{ marginTop: '20px', marginBottom: '10px' }}>
                <img width='25px' src={langItem.icon} alt={langItem.name}></img>
                <div style={{ marginLeft: '20px' }}>{langItem.name}</div>
                <Button
                  style={{ marginLeft: '20px' }}
                  variant="contained"
                  onClick={(e) => askToActivateLanguage(e, langItem)}
                  sx={{
                    backgroundColor: langItem.selected ? 'red' : 'green',
                    '&:hover': {
                      backgroundColor: langItem.selected ? 'darkred' : 'darkgreen',
                    },
                  }}
                >
                  {langItem.selected ? trans.app_language_inactive_button : trans.app_language_active_button}
                </Button>
              {langItem.selected && langItem.is_default == false ?   <Button
                  style={{ marginLeft: '20px' }}
                  variant="contained"
                  color={langItem.is_default ? 'success' : 'secondary'}
                  onClick={() => setDefaultLanguage(langItem.id)}
                >
                  {langItem.is_default ? 'Default' : 'Set as Default'}
                </Button> : null}
              </div>
            );
          })}
          </Grid>
      </Paper>
    </Grid>

  </Grid>


</Container>);
}

export default InitSettings;